import { Link } from "gatsby"
import React from "react"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import Seo from "../components/seo"

const KostenEchtscheiding = () => {
  return (
    <Layout>
      <Seo title="Kosten-bij-een-echtscheiding" />
      <BackgroundImageWrapper hero="hero2" position="center" height="219px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-75px flex justify-center flex-col text-center relative">
          <h1 className="text-4xl font-bold text-white mb-10px">
            Kosten bij een echtscheiding
          </h1>
        </div>
      </BackgroundImageWrapper>

      <section className="pt-50px py-5 bg-secondary">
        <div className="max-w-700px mx-auto flex items-center gap-y-50px flex-col md:flex-row mb-50px">
          <div className="w-1/2 text-center">
            <h2 className="text-27px text-primary mb-10px leading-1.2">
              Gratis
            </h2>
            <p className="text-lg text-primary leading-1.625">
              Kennismakingsgesprek <br /> (30 minuten)
            </p>
          </div>
          <div className="w-1/2 text-center">
            <h2 className="text-27px text-primary mb-10px leading-1.2">€ 90</h2>
            <p className="text-lg text-primary leading-1.625">
              Uurtarief per persoon <br /> exclusief 21% BTW <br /> (€ 108,90
              inclusief BTW)
            </p>
          </div>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-600px mx-auto text-center">
          <h2 className="text-27px text-primary mb-10px leading-1.2">
            Gefinancierde rechtshulp
          </h2>
          <p className="text-lg text-primary leading-1.625">
            Als u voldoet aan de inkomens- en vermogenseisen kunt u in
            aanmerking komen voor een toevoeging (gefinancierde rechtshulp). U
            betaalt voor de mediation dan geen uurtarief maar alleen een
            eenmalige wettelijk vastgestelde{" "}
            <Link to="https://www.rechtsbijstand.nl/mediation-rechtsbijstand/hoeveel-betalen/" className="text-link cursor-pointer">
              &nbsp; eigen bijdrage
            </Link>
            . Voor mediation bedraagt de eenmalige eigen bijdrage € 65 of € 129
            (jaar 2025). Tot een inkomen van € 46.900 bruto per jaar (per
            persoon) kunt u hiervoor in aanmerking komen. Op
            <Link to="https://www.rechtsbijstand.nl/" className="text-link cursor-pointer">
              &nbsp; rechtsbijstand.nl
            </Link>{" "}
            kunt u lezen of u hiervoor in aanmerking komt. We bespreken dit ook
            altijd tijdens het gratis kennismakingsgesprek.
          </p>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px text-primary mb-45px leading-1.2 text-center">
            Bijkomende kosten
          </h2>
          <ul className="list-disc text-left pl-10 mb-30px">
            <li className="text-lg text-primary">
                Griffierechten rechtbank 2025: € 165,50 (zonder toevoeging) of € 45 (met toevoeging)
            </li>
            <li className="text-lg text-primary">
                Kosten gemeentelijke aktes: ca € 17 per stuk (het gaat om de huwelijksakte en een geboorte akte van elk minderjarig kind)
            </li>
          </ul>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-600px mx-auto text-center">
          <h2 className="text-27px text-primary mb-15px leading-1.2">
            Verzekering
          </h2>
          <p className="text-lg text-primary leading-1.625">
            In enkele gevallen valt mediation onder uw
            rechtsbijstandverzekering. Verstandig dus om dit te checken
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default KostenEchtscheiding
