import React from "react"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import Seo from "../components/seo"

const KostenArbeidsconflict = () => {
  return (
    <Layout>
      <Seo title="Kosten-bij-een-arbeidsconflict" />
      <BackgroundImageWrapper hero="hero3" position="center" height="219px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-75px flex justify-center flex-col text-center relative">
          <h1 className="font-bold text-white mb-15px text-3xl">
            Kosten bij een arbeidsconflict
          </h1>
        </div>
      </BackgroundImageWrapper>

      <section className="pt-50px py-5">
        <div className="max-w-700px mx-auto flex items-center gap-y-50px flex-col md:flex-row mb-50px">
          <div className="w-1/2 text-center">
            <h2 className="text-27px text-primary mb-10px leading-1.2">
              € 180
            </h2>
            <p className="text-lg text-primary leading-1.625">
              Uurtarief voor organisaties
              <br />
              <strong>zonder</strong> btw-verrekening
              <br />
              (exclusief 21% BTW)
            </p>
          </div>
          <div className="w-1/2 text-center">
            <h2 className="text-27px text-primary mb-10px leading-1.2">
              € 210
            </h2>
            <p className="text-lg text-primary leading-1.625">
              Uurtarief voor organisaties
              <br />
              <strong>met </strong>
              btw-verrekening
              <br /> (exclusief 21% BTW)
            </p>
          </div>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary">
        <div className="max-w-500px mx-auto text-center">
          <p className="text-lg text-primary leading-1.625">
            Arbeidsmediation wordt vaak door de werkgever betaald. Partijen
            kunnen ook afspraken maken over de verdeling van de kosten.
          </p>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-500px mx-auto text-center">
          <h2 className="text-27px text-primary mb-15px leading-1.2">
            Mediation op locatie
          </h2>
          <p className="text-lg text-primary leading-1.625">
            Bij mediation op locatie wordt de reistijd van de mediator voor de
            helft in rekening gebracht. De kosten van de externe locatie worden
            eveneens bij de opdrachtgever in rekening gebracht.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default KostenArbeidsconflict
